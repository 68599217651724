import React, { useEffect, useState } from 'react';

export const useProductOptions = ({
  categories,
  productKey,
  fitOptions,
  existingLineItemId,
  fabricDisplayValue
}) => {
  const [productOptions, setProductOptions] = useState({});

  const getProductOptions = async () => {
    if (!fitOptions) return;

    for (const fitOption of fitOptions) {
      const shapeCode = fitOption.gpp_shape_code;
      for (const category of categories) {
        let endpoint = `/products/${productKey}/product_option_categories/${category}.json?shape_code=${shapeCode}`;

        if (existingLineItemId) {
          endpoint = endpoint + `&line_item_id=${existingLineItemId}`;
        }

        const response = await fetch(endpoint, {
          headers: { 'Content-Type': 'application/json' }
        });

        const parsedResponse = await response.json();

        if (fabricDisplayValue && category === 'fabric') {
          for (const fabric of parsedResponse) {
            // If the value and name are same, no need to repeat.
            // This is to prevent something like "FW01034646-0002 FW01034646-0002" showing up
            fabric.name =
              fabric.name.includes(fabric.gpp_value) ?
                fabric.name
              : fabric.name + ' ' + fabric.gpp_value;
          }
        }

        setProductOptions((prev) => ({
          ...prev,
          [shapeCode]: {
            ...prev[shapeCode],
            [category]: parsedResponse
          }
        }));
      }
    }
  };

  useEffect(() => { getProductOptions() }, [
    categories,
    productKey,
    fitOptions,
    existingLineItemId,
    fabricDisplayValue
  ]);

  return productOptions;
};

export function useProductPreferences(productKey) {
  const endpoint = `/products/${productKey}/product_preferences.json`;
  const [productPreferencesRequest, setProductPreferencesRequest] =
    useState({ loading: true, data: null, error: null });

  useEffect(() => {
    window
      .fetch(endpoint, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        return response.json();
      })
      .then((parsedResponse) => {
        setProductPreferencesRequest({
          loading: false,
          data: parsedResponse,
          error: null
        });
      })
      .catch((errorResponse) => {
        setProductPreferencesRequest({
          loading: false,
          data: null,
          error: errorResponse.message
        });
      });
  }, [endpoint]);

  return productPreferencesRequest;
}
